nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  /* background-color: rgb(0, 0, 0); */
  text-transform: uppercase;
  padding: 20px 25px 20px;

  position: fixed;
  width: 100%;
  z-index: 10;

  transition-timing-function: ease-in-out;
  transition: all 0.5s;
}

.navOnScroll {
  /* backdrop-filter: blur(5px) saturate(65%) contrast(120%); */
  background-color: #673d89;
  padding: 5px 25px 5px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.navOffScroll {
  background-color: transparent;
  display: grid;
  grid-template-columns: 120px auto 120px;
}

nav .logo {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 36px;
  color: #ffffff;
}

.nav-link {
  color: inherit;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none;
  color: #ffffff;
  border-radius: 75.76px;
  padding: 12px 40px;
  /* width: 170px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid rgb(189, 189, 209); */
  /* padding: 12px; */
}

.telegram {
  display: flex;
  justify-content: flex-end;
}

.nav-link-telegram {
  width: fit-content;
  padding: 12px 12px 7px 10px;
  border-radius: 50%;
  background: #673d89;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

nav .nav-link:is(:hover, :active, :focus),
.nav-link:is(:hover, :active, :focus) a {
  background-color: #f8e1a6;
  color: rgba(0, 0, 0, 0.8);
}

nav .menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #673d89;
  border-radius: 75.7576px;
  height: 48px;
}

/* .nav-link__hidden {
  display: none;
} */

nav a {
  text-decoration: none;
  color: white;
}

.list {
  list-style: none;
  display: flex;
}

@media screen and (max-width: 916px) {
  .menu .list {
    display: none;
  }
}

/* 
@media screen and (max-width: 1135px) {
  .navbar .menu {
    border-radius: 75.7576px;
    height: 48px;
  }

  .nav-link {
    font-size: 18px;
    line-height: 22px;
    padding: 10px 25px;
  }
}

@media screen and (max-width: 935px) {
  .navbar .logo {
    display: none;
  }
  .button__hidden {
    display: none;
  }
  .nav-link__hidden {
    display: block;
  }

  .navbar .menu {
    margin: 0 auto;
  }
}

@media screen and (max-width: 820px) {
  .navbar {
    padding: 25px 0;
  }
}

@media screen and (max-width: 765px) {
  .nav-link {
    padding: 10px 15px;
    flex-wrap: nowrap;
  }
}

@media screen and (max-width: 665px) {
  .navbar .menu {
    border-radius: 0;
    overflow-x: scroll;
    overflow-y: hidden;
    max-height: 45px;
    padding: 35px 0;
  }

  .navbar {
    padding-top: 0;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .list {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
  }

  .list::-webkit-scrollbar {
    display: none;
  }

  .nav-link {
    width: max-content;
  }
} */

/* 
@media screen and (max-width: 765px) {
  .button__hidden {
    display: none;
  }
  .nav-link__hidden {
    display: block;
  }

  .navbar label {
    position: relative;
    z-index: 4;
  }
  .navbar .menu {
    position: absolute;
    z-index: 2;
    width: 250px;
    height: 0;
    overflow: scroll;
    top: 0;
    border-radius: 20px 0 20px 20px;
    right: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list {
    flex-direction: column;
    align-items: center;
  }

  .list a {
    color: white;
  }

  label {
    color: white;
  }

  .navbar label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }

  #toggler:checked ~ .menu {
    height: 350px !important;
  }
} */
