/* main {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.main__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100%;
  /* overflow-y: hidden; */
  /* height: calc(100vh - 98px); */
}

.main__content_text {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 65vh;
}

.main__content h1 {
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 74px;
  line-height: 72px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 8vh;
}

.main__content p {
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  margin-top: 3vh;
}

.main__content button {
  width: 300px;
  height: 60px;
  border-radius: 100px;
  border: none;
  background: #d761a9;
  margin: 0 auto;
  cursor: pointer;
}

.main__content button a {
  text-decoration: none;
  font-size: 22px;
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22.7273px;
  line-height: 27px;
}
.main__content button:hover {
  background: #d55ca6;
}

/* @media screen and (max-width: 460px) {
  .main__content h1 {
    margin-top: 50px;
    font-size: 55px;
    line-height: 76px;
  }
} */

.hero__images {
  display: flex;
  width: 100vw;
  justify-content: space-between;

  /* margin-top: 100px; */
  /* position: relative; */
  position: absolute;
  bottom: -5%;
  /* height: 45vh; */
  /* border: 2px solid yellow; */
}

#hero__img_1 {
  /* position: absolute; */
  bottom: 0;
  /* bottom: -5%; */
  left: 0;
  z-index: 4;
  width: 35%;
}

#hero__img_2 {
  position: absolute;
  /* bottom: 0; */
  bottom: 3%;
  left: 32%;
  z-index: 2;
  width: 40%;
  /* height: auto; */
}

#hero__img_3 {
  /* position: absolute; */
  bottom: 0;
  /* bottom: 15%; */
  right: 0;
  z-index: 3;
  width: 35%;
  /* transform: rotate(-5deg); */
}

@media screen and (max-width: 980px) {
  .hero__images {
    display: flex;
    width: 100vw;
    justify-content: space-between;
    position: absolute;
    bottom: 7%;
  }

  #hero__img_1 {
    bottom: 0;
    left: -20%;
    z-index: 4;
    width: 45%;
  }

  #hero__img_2 {
    position: absolute;
    bottom: 3%;
    left: 25%;
    z-index: 5;
    width: 50%;
  }

  #hero__img_3 {
    bottom: 0;
    right: 0;
    z-index: 3;
    width: 45%;
  }
}

@media screen and (max-width: 768px) {
  .main__content h1 {
    font-weight: 800;
    font-size: 58px;
    line-height: 62px;
  }

  .main__content p {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
  }
}
