.contact-page {
  /* height: calc(100vh - 98px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8%;

  /* justify-content: space-between; */
}

.contact-page__form h1 {
  color: #312e33;
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 76px;
  text-align: center;
  text-transform: capitalize;
  width: 50%;
  /* padding-top: 40px; */
  /* margin: 0 0 4%; */
}

.contact-page__form {
  background-color: #ffffff;
  bottom: 0;
  width: 100%;
  /* min-height: 65vh; */
  border-radius: 50px 50px 0px 0px;
  /* padding-top: 40px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 4%; */
  overflow-y: scroll;
  padding-top: 40px;
}

.contact-page__form::-webkit-scrollbar {
  display: none;
}

.contact-page__form_inner {
  width: 50%;
  display: flex;
  flex-direction: column;
  /* min-height: 60vh; */
  justify-content: space-evenly;
}

.contact-page__form h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  color: #312e33;
  text-align: left;
}

.contact-page__inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;

  /* align-items: center; */
}

.contact-page__inputs div,
.contact-page__inputs button {
  margin-top: 10px;
}

.contact-page__tags {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.contact-page__tag {
  padding: 10px 20px;
  border: 1px solid #312e33;
  border-radius: 100px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  color: #312e33;
  margin: 14px 20px 0px 0;
  cursor: pointer;
}

.contact-page__tag_choosed {
  padding: 10px 20px;

  border-radius: 100px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  color: #312e33;
  margin: 14px 20px 0px 0;
  cursor: pointer;
  background-color: #673d89;
  border: 1px solid #673d89;
  color: #ffffff;
}

.contact-page__form_inner input {
  height: 50px;
  font-size: 22px;
  border-radius: 100px;
  border: 1px solid #312e33;
  width: 100%;
  padding-left: 20px;
}

.contact-page__form_inner button {
  background: #d761a9;
  border-radius: 100px;
  width: 266px;
  height: 60px;
  border: none;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22.7273px;
  line-height: 27px;
  margin: 25px auto 10px;
  color: #ffffff;
  align-self: center;
}

.contact-page__form_inner p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  margin: 5px auto;

  /* Grey main Text */

  color: #312e33;
  /* margin: 16px 0; */
}

@media screen and (max-width: 930px) {
  .contact-page h1 {
    font-size: 40px;
    line-height: 56px;
  }
}

@media screen and (max-width: 685px) {
  .contact-page h1 {
    font-size: 30px;
    line-height: 36px;
    margin: 0 10px;
  }
  .contact-page {
    margin-top: 12%;
  }
}

@media screen and (max-width: 1024px) {
  .contact-page__form_inner {
    width: 70%;
  }
}

@media screen and (max-width: 870px) {
  .contact-page__form_inner {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .contact-page__form_inner {
    width: 90%;
  }

  .contact-page__tag {
    margin: 7px 10px 0 0;
  }

  .contact-page__form_inner p {
    margin: 0;
  }

  .contact-page__form {
    padding: 30px;
  }

  /* .contact-page h1 {
    font-size: 40px;
    line-height: 57px;
  } */
}

@media screen and (max-width: 580px) {
  .contact-page__form_inner,
  .contact-page__form_inner button {
    width: 100%;
  }
  /* .contact-page h1 {
    font-size: 30px;
    line-height: 47px;
  } */
}
