@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* border: 2px solid rgb(209, 231, 87); */
}
body {
  background-color: #522f6d;
  scrollbar-width: 0px;
}
