.why-us {
  padding: 0 calc((100vw - 1400px) / 2);

  /* height: calc(100vh - 98px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 12%; */
  /* justify-content: space-around; */
}

.why-us h1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 96px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  margin: 8% auto 4%;
}

table,
th,
td {
  border-collapse: collapse;
}

.table-container {
  background-color: #ffffff;
  border-radius: 20px;
  width: 75%;
  margin-bottom: 10%;
}

.table-container th {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #312e33;
}

.table-container th:last-child {
  background-color: #d761a9;
  color: #ffffff;
  border-radius: 20px 20px 0 0;
}

.table-container td:last-child {
  background-color: #d761a9;
}
.table-container td {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  width: 260px;
}

.table-container td:first-child,
.table-container th:first-child {
  width: 380px;
  text-align: left;
  padding-left: 40px;
}

.table-container tr {
  height: 70px;
}

.table-container tr:last-child td:last-child {
  border-radius: 0 0 20px 20px;
}

tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.table-container th,
.table-container td {
  border-right: 1px solid rgba(0, 0, 0, 0.3);
}

.table-container tr:last-child,
.table-container td:last-child,
.table-container td:nth-child(3),
.table-container th:last-child,
.table-container th:nth-child(3) {
  border: none;
}

@media screen and (max-width: 930px) {
  .why-us h1 {
    font-size: 40px;
    line-height: 56px;
  }
}

@media screen and (max-width: 685px) {
  .why-us h1 {
    font-size: 30px;
    line-height: 36px;
  }

  .why-us {
    margin-top: 12%;
  }
}

@media screen and (max-width: 1024px) {
  .table-container {
    width: 95%;
  }
}

@media screen and (max-width: 775px) {
  .why-us h1 {
    /* font-size: 36px; */
    /* margin: 50px auto; */
  }
  .table-container {
    width: 90%;
  }

  .table-container th {
    font-size: 22px;
  }

  .table-container td {
    font-size: 18px;
  }

  .table-container tr {
    height: 60px;
  }

  .table-container td:first-child,
  .table-container th:first-child {
    width: 280px;
    text-align: left;
    padding-left: 20px;
  }
}

@media screen and (max-width: 650px) {
  .table-container th {
    font-size: 16px;
    line-height: 20px;
  }

  .table-container td:first-child,
  .table-container th:first-child {
    width: 270px;
    text-align: left;
    padding-left: 20px;
  }

  .table-container td {
    font-size: 16px;
  }

  .table-container tr {
    height: 40px;
  }
}

@media screen and (max-width: 520px) {
  .table-container td:first-child,
  .table-container th:first-child {
    width: 140px;
    text-align: left;
    padding: 15px 0 15px 12px;
    line-height: inherit;
  }

  .table-container {
    width: 98%;
  }
}
