@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap");

.pricing__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100vh; */
  /* padding: 0 calc((100vw - 1354px) / 2); */
  padding: 0 calc((100vw - 1400px) / 2);
  /* margin-top: 45vh; */
}

.pricing__content h1 {
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 96px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 12%;
}

.pricing__content p {
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  margin-top: 18px;
}

.pricing-cards {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

.pricing-card {
  min-width: 260px;
  background-color: #ffffff;
  border-radius: 50px;
  margin: 5px;
  padding: 27px;
  display: inline-block;
  cursor: pointer;
}

.pricing-cards::-webkit-scrollbar {
  display: none;
}

.pricing-card:hover {
  transform: scale(1.02);
}

.pricing-card h2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #312e33;
  margin: 15px 0;
}

.pricing-card h3 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.pricing-card h3 span {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}

.pricing-card_detailed {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.pricing-card_detailed span {
  display: flex;
  align-items: center;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #010101;
}

.pricing-card_detailed span img {
  margin: 10px 10px 10px 0;
}

/* .pricing-card__circle {
  margin: 20px;
} */

/* @media screen and (max-width: 460px) {
  .pricing__content h1 {
    margin-top: 10px;
    font-size: 50px;
    line-height: 56px;
  }
  .pricing__content p {
    margin-top: 8px;
  }
} */

@media screen and (max-width: 930px) {
  .pricing__content h1 {
    font-size: 40px;
    line-height: 56px;
  }

  .pricing__content p {
    display: none;
  }
}

@media screen and (max-width: 685px) {
  .pricing__content h1 {
    font-size: 30px;
    line-height: 36px;
  }
}
