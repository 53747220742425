* {
  /* border: 1px solid yellow; */
}

.works-page {
  background-color: #ffffff;

  padding: 40px 0;

  /* display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 298px); */
}

.works-page h1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 50px;
  text-align: center;
  text-transform: capitalize;
  color: #312e33;
  /* margin: 40px auto; */
}

.works_inner {
  width: 75%;
  margin: 0 auto;
}

.works-card {
  display: flex;
  justify-content: space-between;
  margin: 90px 0;
  align-items: center;
  /* height: 360px; */
}

.works-card img:hover {
  transform: scale(1.01);
}

.works-card img {
  width: 100%;
}

.works-card:nth-child(2) {
  flex-direction: row-reverse;
}

.works-card_left,
.works-card_right {
  width: 46%;
}

.works-card_left {
  display: flex;
  flex-direction: column;
}

.works-card_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.works-card__title,
.works-card__title-mobile {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 44px;
  line-height: 52px;
  color: #312e33;
}

.works-card__title-mobile {
  display: none;
}

.works-card__text {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  /* Grey main Text */
  color: #312e33;
  margin: 30px 0;
}

.works-card__tags {
  display: flex;
  flex-wrap: wrap;
}

.works-card__tags span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  /* Grey main Text */

  color: #312e33;

  border: 1px solid #312e33;
  border-radius: 100px;
  padding: 10px 20px;
  margin: 0 10px 10px 0;
}

@media screen and (max-width: 1285px) {
  .works-card__title {
    font-size: 38px;
    line-height: 42px;
  }

  .works-card__text {
    font-size: 20px;
    line-height: 26px;
    margin: 20px 0;
  }
}

@media screen and (max-width: 1024px) {
  .works_inner {
    width: 95%;
  }
}

@media screen and (max-width: 845px) {
  .works-card__title {
    font-size: 32px;
    line-height: 38px;
  }

  .works-card__text {
    font-size: 18px;
    line-height: 22px;
    margin: 10px 0;
  }
}

@media screen and (max-width: 768px) {
  .works-card {
    display: flex;
    flex-direction: column;
  }

  .works-card:nth-child(2) {
    flex-direction: column;
  }

  .works-card_left,
  .works-card_right {
    width: 420px;
  }

  .works-page {
    margin: 6% 0;
  }

  .works-card__title {
    display: none;
  }
  .works-card__title-mobile {
    display: inline;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 450px) {
  .works-card_left,
  .works-card_right {
    width: 320px;
  }
}

@media screen and (max-width: 375px) {
  .works-card_left,
  .works-card_right {
    width: 280px;
  }
}
